import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AdminCustomerComponent } from './admin-customer/admin-customer.component';
import { AdminUserComponent } from './admin-user/admin-user.component';
import { AdminCustomerDetailComponent } from './admin-customer-detail/admin-customer-detail.component';
import { CustomerArticlesComponent } from './customer-articles/customer-articles.component';
import { CustomerOrdersComponent } from './customer-orders/customer-orders.component';
import { CustomerNewOrderComponent } from './customer-order/customer-new-order.component';
import { OrderDeliverynoteComponent } from './order-deliverynote/order-deliverynote.component';
import { AdminOrdersListComponent } from './admin-orders-list/admin-orders-list.component';
import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';
import { RoleGuardService as RoleGuard } from './auth/role-guard.service';
import { AdminOrdersReportComponent } from './admin-orders-report/admin-orders-report.component';
import { AdminOrdersReportDetailComponent } from './admin-orders-report-detail/admin-orders-report-detail.component';
import { UserSettingsComponent } from "./user-settings/user-settings.component";
import { RegisterComponent } from './register/register.component';
const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'admin-customer', component: AdminCustomerComponent, canActivate: [RoleGuard], data: { expectedRole: 'admin' } },
  {
    path: 'admin-customer-detail/:id', component: AdminCustomerDetailComponent, canActivate: [RoleGuard],
    data: { expectedRole: 'admin' }
  },
  { path: 'admin-user', component: AdminUserComponent, canActivate: [RoleGuard], data: { expectedRole: 'admin' } },
  { path: 'customer-articles', component: CustomerArticlesComponent, canActivate: [AuthGuard] },
  { path: 'customer-orders', component: CustomerOrdersComponent, canActivate: [AuthGuard] },
  { path: 'customer-orders/:id', component: CustomerNewOrderComponent, canActivate: [AuthGuard] },
  { path: 'order-deliverynote/:id', component: OrderDeliverynoteComponent, canActivate: [AuthGuard] },
  { path: 'user-settings', component: UserSettingsComponent, canActivate: [AuthGuard] },
  { path: 'admin-orders-list', component: AdminOrdersListComponent, canActivate: [RoleGuard], data: { expectedRole: 'admin' } },
  { path: 'admin-orders-report', component: AdminOrdersReportComponent, canActivate: [RoleGuard], data: { expectedRole: 'admin' } },

  {
    path: 'admin-orders-report/:year/:month/:customerId', component: AdminOrdersReportDetailComponent, canActivate: [RoleGuard],
    data: { expectedRole: 'admin' }
  },
  //{ path: '', redirectTo: 'admin-customer', pathMatch: 'full' },
  { path: '**', redirectTo: 'admin-customer' }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
