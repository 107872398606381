import Auth from '@aws-amplify/auth';

const awsmobile = {
  aws_appsync_graphqlEndpoint: 'https://api.imagingyou.taimos.cloud/graphql',
  aws_appsync_region: 'eu-central-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'null',
  aws_user_pools_id: 'eu-central-1_TIBe5I7Pr',
  aws_user_pools_web_client_id: '1qh3eu7illprqhl1r3egnr60k5',
  aws_cognito_region: 'eu-central-1',
  graphql_headers: async () => {
    return { Authorization: (await Auth.currentSession()).getIdToken().getJwtToken() };
  },
  register_url: 'https://hddwnhg4d5hcluqcmrggvfvdr40qgejt.lambda-url.eu-central-1.on.aws/',
};

export default awsmobile;
