import { Component, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { Inject } from '@angular/core';
import { APIService, Article, Customer, Order, User } from '../API.service';
import { CustomerDialogBoxComponent } from '../customer-dialog-box/customer-dialog-box.component';
import { UserDialogBoxComponent } from '../user-dialog-box/user-dialog-box.component';
import { ArticleDialogBoxComponent } from '../article-dialog-box/article-dialog-box.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ArticleRemoveDialogBoxComponent } from '../article-remove-dialog-box/article-remove-dialog-box.component';

@Component({
  selector: 'app-admin-customer-detail',
  templateUrl: './admin-customer-detail.component.html',
  styleUrls: ['./admin-customer-detail.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],

})
export class AdminCustomerDetailComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private api: APIService, @Inject(MatDialog) public dialog: MatDialog) { }

  customerID = '';
  CustomerDetails!: any;

  showArticleAttributes: string = 'true';
  orderAddressMandatory: string = 'true';
  orderApprovalProcess: string = 'false';

  displayedColumnsUserList: string[] = ['displayName', 'email', 'phone', 'position', 'action'];
  //displayedColumnsArticleList: string[] = ['images', 'name', 'measurements', 'weight', 'amount', 'action'];
  displayedColumnsOrderList: string[] = ['event', 'von', 'bis', 'state', 'print'];

  UserList = [];
  ArticleList = [];
  OrderList!: MatTableDataSource<Order>;

  // Dynamically create the displayed columns array based on variables
  displayedColumnsArticleList: string[] = [];


  ngOnInit() {
    this.customerID = this.route.snapshot.paramMap.get('id') || '';
    this.api.GetCustomer(this.customerID).then(customer => {
      this.CustomerDetails = customer;
      //console.log(this.CustomerDetails);
      if (customer.features?.showArticleAttributes != null) {
        this.showArticleAttributes = customer.features?.showArticleAttributes.toString();
        localStorage.setItem('showArticleAttributes', this.showArticleAttributes);
      }
      if (customer.features?.orderAddressMandatory != null) {
        this.orderAddressMandatory = customer.features?.orderAddressMandatory.toString();
        localStorage.setItem('orderAddressMandatory', this.orderAddressMandatory);
      }
      if (customer.features?.orderApprovalProcess != null) {
        this.orderApprovalProcess = customer.features?.orderApprovalProcess.toString();
        localStorage.setItem('orderApprovalProcess', this.orderApprovalProcess);
      }

      this.displayedColumnsArticleList = this.generateDisplayedColumns();
    });
    this.api.GetUsersByCustomer(this.customerID).then(users => {
      // @ts-expect-error
      this.UserList = ((users as any) as UserData[]);
      //console.log(this.UserList);
    });
    this.api.GetArticlesByCustomer(this.customerID).then(articles => {
      // @ts-expect-error
      this.ArticleList = ((articles as any) as ArticleData[]);
      //console.log(this.ArticleList);
    });
    this.api.GetOrdersByCustomer(this.customerID).then(orders => {
      this.OrderList = new MatTableDataSource((orders as any) as Order[]);
      this.OrderList.paginator = this.paginator;
      //console.log(this.OrderList);
    });
  }


  // Function to generate the displayed columns array dynamically
  private generateDisplayedColumns(): string[] {
    const defaultColumns: string[] = ['images', 'name', 'amount', 'action'];
    const dynamicColumns: string[] = [];

    // Add columns before 'weight'
    defaultColumns.forEach((column) => {
      if (column === 'amount') {
        // Add 'measurements & weight' column before 'amount' if not excluded
        if (this.showArticleAttributes == 'true') {
          dynamicColumns.push('measurements');
          dynamicColumns.push('weight');
        }
      }
      dynamicColumns.push(column);
    });

    return dynamicColumns;
  }

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  openCustomerDialog(action: string, obj: Customer) {
    const dialogRef = this.dialog.open(CustomerDialogBoxComponent, {
      data: { ...obj, action }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result || result.event === 'cancel') {
        return;
      }
      const data = result.data;
      delete data.action;
      this.updateCustomerData(data);
    });
  }

  openUserDialog(action: string, obj?: User) {
    if (action === 'anlegen') {
      obj = {
        __typename: 'User',
        name: '',
        displayName: '',
        email: '',
        phone: '',
        position: '',
        customer: this.customerID,
        enabled: true,
        status: 'CONFIRMED',
        sub: '',
      };
    }
    const dialogRef = this.dialog.open(UserDialogBoxComponent, {
      data: { ...obj, action }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result || result.event === 'Cancel') {
        return;
      }
      const data = result.data;
      delete data.action;

      if (result.event === 'anlegen') {
        this.addUserRowData(data);
      } else if (result.event === 'bearbeiten') {
        this.updateUserRowData(data);
      } else if (result.event === 'löschen') {
        this.deleteUserRowData(data);
      }
    });
  }

  openArticleDialog(action: string, article?: Article, taglist?: any) {
    if (action === 'anlegen') {
      article = {
        __typename: 'Article',
        id: '',
        name: '',
        measurements: '',
        weight: '',
        amount: 1,
        description: '',
        assets: [],
        tags: [],
        customerId: this.customerID,
      };
    }
    taglist = this.CustomerDetails.tags;
    const dialogRef = this.dialog.open(ArticleDialogBoxComponent, {
      data: { article, action, taglist },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      const data = result.data;
      delete data.action;

      if (result.event === 'anlegen') {
        this.addArticleRowData(data);
      } else if (result.event === 'upload') {
        this.updateArticleRowData(data);
      } else if (result.event === 'bearbeiten') {
        this.updateArticleRowData(data);
      } else if (result.event === 'löschen') {
        this.deleteArticleRowData(data);
      } else if (result.event === 'Cancel') {
        this.canceledArticleDialog();
      }
    });
  }

  openRemoveArticleDialog(article: Article) {
    const dialogRef = this.dialog.open(ArticleRemoveDialogBoxComponent, {
      data: { article },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      const data = result.data;
      delete data.action;

      if (result.event === 'löschen') {
        this.deleteArticleRowData(data);
      } else if (result.event === 'Cancel') {
        this.canceledArticleDialog();
      }
    });
  }

  updateCustomerData(data: Customer) {
    this.api.SaveCustomer(data.id!, {
      name: data.name!,
      email: data.email,
      address: {
        street: data.address?.street ?? '',
        zip: data.address?.zip ?? '',
        city: data.address?.city ?? '',
        country: data.address?.country ?? '',
      },
      orderNote: data.orderNote ?? '',
      customMailText: data.customMailText ?? '',
      costCenter: data.costCenter,
      tags: data.tags,
      pickupNotes: data.pickupNotes,
      selfRegistrationDomains: data.selfRegistrationDomains,
      features: {
        orderAddressMandatory: data.features?.orderAddressMandatory,
        orderApprovalProcess: data.features?.orderApprovalProcess,
        showArticleAttributes: data.features?.showArticleAttributes,
        pickupAddress: data.features?.pickupAddress,
        hideOrderAddress: data.features?.hideOrderAddress,
        specificDeliveryTime: data.features?.specificDeliveryTime,
      }
    }).then(() => this.ngOnInit());
  }

  addUserRowData(data: User) {
    this.api.AddUser(data.customer!, data.email!, data.displayName!, data.position!, data.phone!).then(() => this.ngOnInit());
  }

  updateUserRowData(data: User) {
    this.api.ModifyUser(data.customer!, data.email!, data.displayName!, data.position!, data.phone!).then(() => this.ngOnInit());
  }

  deleteUserRowData(data: User) {
    this.api.RemoveUser(data.customer!, data.email!).then(() => {
      this.ngOnInit();
    });
  }

  addArticleRowData(data: Article) {
    //console.log('Saving article');
    this.api.AddArticle(this.customerID, {
      name: data.name!,
      description: data.description,
      amount: data.amount,
      measurements: data.measurements,
      weight: data.weight,
      tags: data.tags,
    }).then((saved) => {
      //console.log(saved);
      this.ngOnInit();
    });
  }

  updateArticleRowData(data: Article) {
    this.api.SaveArticle(data.customerId!, data.id!, {
      name: data.name!,
      description: data.description,
      amount: data.amount,
      measurements: data.measurements,
      weight: data.weight,
      tags: data.tags,
    }).then((saved) => {
      //console.log(saved);
      this.ngOnInit();
    });
  }

  deleteArticleRowData(data: Article) {
    this.api.RemoveArticle(data.customerId!, data.id!).then(() => {
      this.ngOnInit();
    });
  }

  canceledArticleDialog() {
    //console.log('Cancelled!');
    this.ngOnInit();
  }

  isRecent(createdAt: string | Date | null): boolean {
    if (!createdAt) {
      return false;
    }

    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);

    const entryDate = typeof createdAt === 'string' ? new Date(createdAt) : createdAt;

    if (isNaN(entryDate.getTime())) {
      console.error('Invalid date format:', createdAt);
      return false;
    }

    return entryDate >= thirtyDaysAgo;
  }


}
