import { Component, Inject, Optional, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APIService, Customer } from '../API.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-customer-dialog-box',
  templateUrl: './customer-dialog-box.component.html',
  styleUrls: ['./customer-dialog-box.component.scss']
})
export class CustomerDialogBoxComponent implements OnInit {

  action: string;
  local_data: any; //Customer & { action: string };
  now = new Date().getTime();

  constructor(
    public dialogRef: MatDialogRef<CustomerDialogBoxComponent>,
    private api: APIService,
    private http: HttpClient,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Customer & { action: string }) {
    //console.log(data);
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }

  doAction() {
    //console.log("doAction: " + this.action);
    // Only process the comma-separated input on submit
    this.tagsAndCostChange();
    this.selfRegistrationDomainsInputChange();
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'cancel' }); //Abbrechen sollte keine Daten verändern - wirft noch Fehler
  }

  costCentersInput: string = ''; // Holds the comma-separated input string
  tagsInput: string = ''; // Holds the comma-separated input string
  selfRegistrationDomainsInput: string = '';  // Holds the comma-separated input string

  // Initialize costCentersInput and tagsInput
  ngOnInit() {
    this.costCentersInput = (this.local_data.costCenter?.join(', ')) || '';
    this.tagsInput = (this.local_data.tags?.join(', ')) || '';
    this.selfRegistrationDomainsInput = (this.local_data.selfRegistrationDomains?.join(', ')) || '';
  }
  // Method to parse and update local_data.costCenters based on costCentersInput
  tagsAndCostChange() {
    this.local_data.costCenter = this.costCentersInput
      .split(',')
      .map(name => name.trim())
      .filter(name => name !== ''); // Remove empty strings
    this.local_data.tags = this.tagsInput
      .split(',')
      .map(tag => tag.trim())
      .filter(tag => tag !== ''); // Remove empty strings
  }
  selfRegistrationDomainsInputChange() {
    this.local_data.selfRegistrationDomains = this.selfRegistrationDomainsInput
      .split(',')
      .map(domain => domain.trim())
      .filter(domain => domain !== ''); // Remove empty strings
  }

  async uploadFile(event: Event) {
    //console.log("FIlE Upload");
    const fileList = (event.target as HTMLInputElement).files;

    if (fileList && fileList.length == 1) {
      //console.log('uploading...');
      //console.log(fileList);
      // tslint:disable-next-line: prefer-for-of no - Iterator on FileList
      const file = fileList[0];
      //console.log(file);

      // add logo to customer and get upload url
      const uploadMeta = await this.api.AddCustomerLogo(this.local_data.id!, file.type);
      //console.log(`Created asset for customer id ${uploadMeta.id}`);

      // upload asset to S3 with pre-signed URL
      await this.http.put(uploadMeta.uploadUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      }).toPromise();
      //console.log(`Uploaded asset with id ${uploadMeta.id}`);
      this.local_data.logoUrl = uploadMeta.assetUrl;
      this.local_data.logoMediaType = file.type;
    }
  }

  /*
    deleteLogo() {
      console.log('Logo deleted!');
      this.local_data.logoUrl = null;
      this.local_data.logoMediaType = null;
    }
  */
}
