<h1 mat-dialog-title *ngIf="action == 'anlegen'">{{ 'customerdialog.titelNew' | transloco }}</h1>
<h1 mat-dialog-title *ngIf="action == 'bearbeiten'">{{ 'customerdialog.titelEdit' | transloco }}</h1>
<h1 mat-dialog-title *ngIf="action == 'löschen'">{{ 'customerdialog.titelDelete' | transloco }}</h1>


<div mat-dialog-content class="form" *ngIf="action != 'löschen'; else elseTemplate">
  <mat-form-field class="dataFieldMax">
    <input placeholder="{{ 'customerdialog.name' | transloco }}" matInput [(ngModel)]="local_data.name">
  </mat-form-field>
  <mat-form-field class="dataFieldMax">
    <input placeholder="{{ 'customerdialog.street' | transloco }}" matInput [(ngModel)]="local_data.address.street">
  </mat-form-field>
  <mat-form-field class="dataFieldShort">
    <input placeholder="{{ 'customerdialog.plz' | transloco }}" matInput [(ngModel)]="local_data.address.zip">
  </mat-form-field>

  <mat-form-field class="dataField">
    <input placeholder="{{ 'customerdialog.city' | transloco }}" matInput [(ngModel)]="local_data.address.city">
  </mat-form-field>

  <mat-form-field class="dataField30">
    <input placeholder="{{ 'customerdialog.country' | transloco }}" matInput [(ngModel)]="local_data.address.country">
  </mat-form-field>

  <mat-form-field class="dataFieldMax">
    <input placeholder="{{ 'customerdialog.email' | transloco }}" matInput [(ngModel)]="local_data.email">
  </mat-form-field>

  <mat-form-field class="dataFieldMax">
    <textarea rows="5" placeholder="{{ 'customerdialog.hint' | transloco }}" matInput [(ngModel)]="local_data.orderNote"></textarea>
  </mat-form-field>

  <mat-form-field class="dataFieldMax">
    <textarea rows="5" placeholder="{{ 'customerdialog.confirmationText' | transloco }}" matInput [(ngModel)]="local_data.customMailText"></textarea>
  </mat-form-field>

  <mat-form-field class="dataFieldMax">
    <textarea rows="5" placeholder="{{ 'customerdialog.pickupNotes' | transloco }}" matInput [(ngModel)]="local_data.pickupNotes"></textarea>
  </mat-form-field>


  <mat-form-field class="dataFieldMax">
    <input
      matInput
      placeholder="Enter comma-separated cost center names"
      [(ngModel)]="costCentersInput" />
  </mat-form-field>
  <mat-form-field class="dataFieldMax">
    <input
      matInput
      placeholder="Enter comma-separated tags"
      [(ngModel)]="tagsInput" />
  </mat-form-field>

  <mat-form-field class="dataFieldMax">
    <input placeholder="{{ 'customerdialog.selfRegistrationDomains' | transloco }}" matInput [(ngModel)]="selfRegistrationDomainsInput">
  </mat-form-field>

  <div class="customerlogo" *ngIf="local_data.logoUrl">
      <div class="uploadedImage" [ngStyle]="{'background-image': 'url(' + local_data.logoUrl + '?v=' + now +')'}">
        <!-- <button mat-mini-fab color="warn" class="delete-file" (click)="deleteAttachment(local_data.customerLogo?.id)">
          <mat-icon>delete</mat-icon>
        </button> -->
      </div>
  </div>
  <div class="uploadfilecontainer" *ngIf="local_data.id">
    <mat-icon *ngIf="!local_data.logoUrl" color="primary" (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFile($event)">add_photo_alternate</mat-icon>
    <label *ngIf="!local_data.logoUrl" (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFile($event)">Kundenlogo hinzufügen</label>

    <mat-icon *ngIf="local_data.logoUrl" color="primary" (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFile($event)">edit</mat-icon>
    <!--<mat-icon *ngIf="local_data.logoUrl" (click)="deleteLogo()">delete</mat-icon>-->

    <input hidden type="file" #fileInput (change)="uploadFile($event)" multiple>
  </div>
  <!--
  <ng-container *ngIf="action == 'upload' || article.assets?.length">
    <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFile($event)">
      <mat-icon color="primary">add_photo_alternate</mat-icon>
      <input hidden type="file" #fileInput (change)="uploadFile($event)" multiple>
    </div>
    <div class="files-list">
      <ng-container *ngFor="let file of article.assets;let i= index">
        <div class="uploadedImage" [ngStyle]="{'background-image': 'url(' + file?.url + ')'}">
          <button mat-mini-fab color="warn" class="delete-file" (click)="deleteAttachment(file?.id, i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </ng-container>
    </div>
  </ng-container>
  -->

  <section class="checkbox-section">
    <mat-checkbox *ngIf="local_data?.features" [(ngModel)]="local_data.features.orderAddressMandatory">{{ 'customerdialog.checkOrderAddressMandatory' | transloco }}</mat-checkbox>
    <mat-checkbox *ngIf="local_data?.features" [(ngModel)]="local_data.features.orderApprovalProcess">{{ 'customerdialog.checkApproval' | transloco }}</mat-checkbox>
    <mat-checkbox *ngIf="local_data?.features" [(ngModel)]="local_data.features.showArticleAttributes">{{ 'customerdialog.checkExtended' | transloco }}</mat-checkbox>
    <mat-checkbox *ngIf="local_data?.features" [(ngModel)]="local_data.features.pickupAddress">{{ 'customerdialog.checkPickup' | transloco }}</mat-checkbox>
    <mat-checkbox *ngIf="local_data?.features" [(ngModel)]="local_data.features.hideOrderAddress">{{ 'customerdialog.checkOrderaddress' | transloco }}</mat-checkbox>
    <mat-checkbox *ngIf="local_data?.features" [(ngModel)]="local_data.features.specificDeliveryTime">{{ 'customerdialog.checkWindow' | transloco }}</mat-checkbox>
  </section>
</div>

<ng-template #elseTemplate>
  <p>{{ 'customerdialog.customer1' | transloco }} <b>{{local_data.name}}</b> {{ 'customerdialog.customer2' | transloco }}</p>
</ng-template>

<div mat-dialog-actions class="actions">
  <button mat-button (click)="doAction()" mat-flat-button color="accent">{{ 'customerdialog.ok' | transloco }}</button>
  <button mat-button (click)="closeDialog()">{{ 'customerdialog.cancel' | transloco }}</button>
</div>
