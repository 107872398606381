import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import awsmobile from 'src/environments/aws-exports.dev';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  bgImage = 'url("../../assets/img/bg/1.jpg")';
  registerForm!: FormGroup;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly http: HttpClient,
  ) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      email: [''],
      displayName: [''],
      position: [''],
      phone: [''],
    });
  }

  registerUser() {
    console.log(this.registerForm.value);

    this.http.post(awsmobile.register_url, {
      email: this.registerForm.controls['email'].value,
      displayName: this.registerForm.controls['displayName'].value,
      position: this.registerForm.controls['position'].value,
      phone: this.registerForm.controls['phone'].value,
    }).subscribe((response) => {
      console.log(response);
    });
  }
}
