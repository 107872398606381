<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center" [ngStyle]="{'background-image': bgImage}">

  <mat-card appearance="outlined" class="box">
    <mat-card-header>
      <mat-card-title>{{ 'register.title' | transloco }}</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <form class="login-form" [formGroup]="registerForm" (ngSubmit)="registerUser()">
        <mat-form-field class="full-width">
          <input type="text" matInput placeholder="{{ 'register.placeUser' | transloco }}" formControlName="email" />
        </mat-form-field>
        <mat-form-field class="full-width">
          <input type="text" matInput placeholder="{{ 'register.placeDisplayName' | transloco }}" formControlName="displayName" />
        </mat-form-field>
        <mat-form-field class="full-width">
          <input type="text" matInput placeholder="{{ 'register.placePosition' | transloco }}" formControlName="position" />
        </mat-form-field>
        <mat-form-field class="full-width">
          <input type="text" matInput placeholder="{{ 'register.placePhone' | transloco }}" formControlName="phone" />
        </mat-form-field>

        <button type=submit mat-stroked-button color="accent"
          class="btn-block">{{ 'register.button' | transloco }}</button>
      </form>
    </mat-card-content>
  </mat-card>

</div>
