import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { APIService, GetOpenOrdersQuery } from '../API.service';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NewOrderDialogBoxComponent } from '../new-order-dialog-box/new-order-dialog-box.component';
import { OrderRemoveDialogBoxComponent } from '../order-remove-dialog-box/order-remove-dialog-box.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-admin-orders-list',
  templateUrl: './admin-orders-list.component.html',
  styleUrls: ['./admin-orders-list.component.scss']
})
export class AdminOrdersListComponent implements OnInit {
  loadingBar = false;
  displayedColumnsOpenOrdersList: string[] = ['event', 'von', 'bis', 'state', 'print'];
  OpenOrdersList: MatTableDataSource<GetOpenOrdersQuery> = new MatTableDataSource<GetOpenOrdersQuery>([]);

  // Filter form controls for date range
  filterDates = {
    fromDate: new FormControl(null),
    toDate: new FormControl(null)
  };

  // Filter for text input (general filter)
  generalFilter: string = '';

  constructor(
    private api: APIService,
    public dialog: MatDialog,
    private router: Router,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.loadingBar = true;
    //console.log('getting open orders...');
    this.api.GetOpenOrders()
      .then(orders => {
        this.OpenOrdersList = new MatTableDataSource(orders);
        this.OpenOrdersList.data.sort((a, b) => {
          return new Date(b.deliveryDate).getTime() - new Date(a.deliveryDate).getTime();
        });
        //console.log('OrderList:', orders);
        this.loadingBar = false;
      })
      .catch((error) => {
        console.log('could not get open orders');
      });
  }

  ngAfterViewInit() {
    // Subscribe to date picker changes
    this.filterDates.fromDate.valueChanges.subscribe(() => {
      this.applyFilters();
    });
    this.filterDates.toDate.valueChanges.subscribe(() => {
      this.applyFilters();
    });
  }

  // This method is called when the user enters text into the general filter input field
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.generalFilter = filterValue;
    this.applyFilters();
  }

  // This method combines both filters and applies them to the table
  applyFilters() {
    const fromDate = this.filterDates.fromDate.value;
    const toDate = this.filterDates.toDate.value;
    //console.log('fromDate: ' + fromDate + ' - toDate: ' + toDate)

    // Set the filter predicate with both date and general filter logic
    this.OpenOrdersList.filterPredicate = (data: GetOpenOrdersQuery, filter: string) => {
      // Normalize the 'from' and 'to' dates to be at the start of the day (00:00:00)
      const fromDateISO = fromDate ? this.datePipe.transform(fromDate, 'yyyy-MM-dd') : null;
      const toDateISO = toDate ? this.datePipe.transform(toDate, 'yyyy-MM-dd') : null;

      // Convert 'fromDate' and 'toDate' to start-of-day time for comparison
      const startOfDayFrom = fromDateISO ? new Date(fromDateISO + 'T00:00:00') : null;
      const endOfDayTo = toDateISO ? new Date(toDateISO + 'T23:59:59') : null;

      // Convert deliveryDate and pickupDate from the data to Date objects
      const deliveryDate = new Date(data.deliveryDate);
      const pickupDate = new Date(data.pickupDate);
      //console.log('deliveryDate: ' + deliveryDate + ' - pickupDate: ' + pickupDate)

      // Check if the 'deliveryDate' is greater than or equal to 'startOfDayFrom'
      const afterFromDate = startOfDayFrom ? deliveryDate >= startOfDayFrom : true;

      // Check if the 'pickupDate' is less than or equal to 'endOfDayTo'
      const beforeToDate = endOfDayTo ? pickupDate <= endOfDayTo : true;

      // Apply general filter to check if any text in the row matches the general filter input
      const matchesGeneralFilter = data.eventName.toLowerCase().includes(this.generalFilter) ||
        data.state.toLowerCase().includes(this.generalFilter);

      // Return true if all conditions (general filter + date filters) are satisfied
      return (afterFromDate && beforeToDate) && matchesGeneralFilter;
    };

    // Apply the filter on the dataSource (this triggers the filterPredicate to be executed)
    this.OpenOrdersList.filter = 'apply filter';
  }

  clearFilters() {
    // Reset the general filter
    this.generalFilter = '';

    // Reset the date filters
    this.filterDates.fromDate.setValue(null);
    this.filterDates.toDate.setValue(null);

    // Apply the filters after resetting them
    this.applyFilters();
  }

  // Edit order logic (same as previous)
  editOrder(order: GetOpenOrdersQuery) {
    const obj = {
      _id: order.id,
      event: order.eventName,
      from: order.deliveryDate,
      to: order.pickupDate,
      blockStart: order.blockStart,
      blockEnd: order.blockEnd,
      deliveryWindow: order.deliveryWindow,
      pickupWindow: order.pickupWindow,
      address: order.deliveryAddress,
      orderAddress: order.orderAddress,
      articles: undefined,
      state: order.state
    };
    const dialogRef = this.dialog.open(NewOrderDialogBoxComponent, {
      data: { ...obj, action: 'adminEdit' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;
      const orderData = result.data;
      delete orderData.action;

      if (result.event === 'adminEdit') {
        this.api.ChangeOrder(orderData.id, {
          eventName: orderData.event,
          orderAddress: orderData.orderAddress,
          deliveryAddress: orderData.address,
          deliveryWindow: orderData.deliveryWindow,
          pickupWindow: orderData.pickupWindow,
          deliveryDate: orderData.from,
          pickupDate: orderData.to,
          blockStart: orderData.blockStart,
          blockEnd: orderData.blockEnd
        }).then((res) => {
          //console.log(res);
          this.ngOnInit();
        });
      }
    });
  }

  // Delete order logic (same as previous)
  deleteOrder(order: GetOpenOrdersQuery) {
    const dialogRef = this.dialog.open(OrderRemoveDialogBoxComponent, {
      data: { order }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;

      const data = result.data;
      delete data.action;

      if (result.event === 'löschen') {
        this.api.RemoveOrderAsAdmin(order.customerId, order.id).then(() => {
          this.ngOnInit();
        });
      }
    });
  }
}
