import { Component, OnInit } from '@angular/core';
import { APIService, GetOrderSummariesQuery } from '../API.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-orders-report',
  templateUrl: './admin-orders-report.component.html',
  styleUrls: ['./admin-orders-report.component.scss']
})
export class AdminOrdersReportComponent implements OnInit {

  constructor(private api: APIService, public dialog: MatDialog, private router: Router) { }

  loadingBar = false;
  displayedColumns: string[] = ['year', 'month', 'customer'];
  SummaryList: MatTableDataSource<GetOrderSummariesQuery> = new MatTableDataSource<GetOrderSummariesQuery>([]);

  ngOnInit() {
    this.loadingBar = true;
    //console.log('getting order summaries ...');

    this.api.GetOrderSummaries()
      .then(orders => {
        this.SummaryList = new MatTableDataSource(orders);
        this.SummaryList.data.sort((a, b) => {
          return new Date(b.year).getTime() - new Date(a.year).getTime() ||
            new Date(b.month).getTime() - new Date(a.month).getTime() ||
            new Date(b.customerName).getTime() - new Date(a.customerName).getTime();
        });
        //console.log(this.SummaryList);
        this.loadingBar = false;
      })
      .catch((error) => {
        console.log('could not get order summaries');
      });
  }

}
